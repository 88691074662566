<template>
  <div>
    <div class="card my-4 p-1">
<!--      <div class="row">-->
<!--        <div class="col-6 text-right">-->
<!--          <b-card class="card d-flex">-->
<!--            <b-form-radio v-model="typeEstimate" value="existing" /><h3> Select an existing a quote</h3>-->
<!--          </b-card>-->
<!--        </div>-->
<!--        <div class="col-6 d-flex text-start">-->
<!--          <b-card class="card">-->
<!--            <b-form-radio v-model="typeEstimate" value="new" /><h3> Simulate creating a quote</h3>-->
<!--          </b-card>-->
<!--        </div>-->
<!--      </div>-->
      <div class="d-flex justify-content-center m-3">
        <div class="row">
          <div class="col-12">
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="CSV"
                    :rules="{
                      required: true,
                    }"
                  >
                    <h2>Compare Specification Sets Tool</h2>
                    <b-form-group style="padding-top: 21px">
                      <label for="InputHelp">CAD Data</label>

                      <b-form-file
                        v-model="file"
                        :state="!errors[0]"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".csv"
                      />
                      <br>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="SQFT"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-group>
                      <label for="InputHelp">Project sqft</label>
                      <b-input-group append="SQFT">
                        <b-form-input
                          v-model="sqft"
                          type="number"
                          placeholder="ex.100.50"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="InputHelp">Drywall Takeoff (W1)</label>
                    <b-input-group append="LF">
                      <b-form-input
                        v-model="drywall"
                        type="number"
                        placeholder="ex.100.50"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="InputHelp">Glazing Takeoff (G1)</label>
                    <b-input-group append="LF">
                      <b-form-input
                        v-model="glazing"
                        type="number"
                        placeholder="ex.100.50"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="InputHelp">Demising Wall Takeoff (W2)</label>
                    <small class="text-muted"> optional</small>
                    <b-input-group append="LF">
                      <b-form-input
                        v-model="demising"
                        type="number"
                        placeholder="ex.100.00"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <button
                    class="btn btn-primary"
                    @click="sendInitSpecSets"
                  >
                    {{ quote ? "Submit New" : "Submit" }}
                  </button>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="text-center">
          <h2>{{ quote ? quote.address : "" }}</h2>
          <h4>
            {{
              quote
                ? Number(quote.sqft).toLocaleString("en-CA", {
                  minimumFractionDigits: 2
                })
                : 0
            }}
            sqft
          </h4>
        </div>
        <div
          v-if="quote"
          class="row m-4"
        >
          <div class="col-4 ">
            <div class="mx-1">
              <div class="w-100 my-2">
                <h5 class="text-muted">
                  Base quote
                </h5>
              </div>
              <!-- start:: revision details -->
              <div class="my-1 mr-3">
                <div class="row">
                  <div class="col-6">
                    <span class="text-bold text-muted"> PROJECT TOTAL </span>
                    <h5>
                      {{
                        Number(
                          ctrBaseQuoteSubtotal * 1 +
                            ffeBaseQuoteSubtotal * 1 +
                            avitBaseQuoteSubtotal * 1 +
                            softCostBaseQuoteSubtotal * 1
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                  <div class="col-6 text-right">
                    <span class="text-bold text-muted"> PROJECT $/SQFT </span>
                    <h5>
                      {{
                        Number(
                          (ctrBaseQuoteSubtotal * 1 +
                            ffeBaseQuoteSubtotal * 1 +
                            avitBaseQuoteSubtotal * 1 +
                            softCostBaseQuoteSubtotal * 1) /
                            (quote ? quote.sqft : 0)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                </div>
              </div>
              <!-- end:: revision details -->
              <!-- start:: project breakdown -->
              <div>
                <app-collapse accordion>
                  <app-collapse-item
                    title="Hard Construction"
                    :amount="ctrBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  >
                    Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant.
                    Tootsie roll sweet candy bear claw chupa chups lollipop toffee.
                    Macaroon donut liquorice powder candy carrot cake macaroon fruitcake.
                  </app-collapse-item>
                  <app-collapse-item
                    title="Furniture"
                    :amount="ffeBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  />
                  <app-collapse-item
                    title="Audio Visual & Networking"
                    :amount="avitBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  />
                  <app-collapse-item
                    title="Soft Costs"
                    :amount="softCostBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  />
                </app-collapse>
              </div>
              <!-- end:: project breakdown -->
            </div>
          </div>
          <div class="col-4">
            <div class="mx-1">
              <div class="w-100 my-1">
                <v-select
                  v-model="firstSpecSet"
                  label="name"
                  :options="specification_sets"
                  item-value="id"
                  :reduce="(specification_sets) => specification_sets.id"
                  :selectable="
                    (specification_sets) =>
                      specification_sets.id != firstSpecSet &&
                      specification_sets.id != secondSpecSet
                  "
                  :clearable="false"
                  @input="setSpecSetsFirstQuote"
                />
              </div>

              <!-- start:: revision details -->
              <div class="my-1 mr-3">
                <div class="row">
                  <div class="col-6">
                    <span class="text-bold text-muted"> PROJECT TOTAL </span>
                    <h5 class="ml-0">
                      {{
                        Number(
                          ctrBaseQuoteSubtotalA * 1 +
                            ffeBaseQuoteSubtotalA * 1 +
                            avitBaseQuoteSubtotalA * 1 +
                            softCostBaseQuoteSubtotal * 1
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                  <div class="col-6 text-right">
                    <span class="text-bold text-muted"> PROJECT $/SQFT </span>
                    <h5>
                      {{
                        Number(
                          (ctrBaseQuoteSubtotalA * 1 +
                            ffeBaseQuoteSubtotalA * 1 +
                            avitBaseQuoteSubtotalA * 1 +
                            softCostBaseQuoteSubtotal * 1) /
                            (quote ? quote.sqft : 0)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                </div>
              </div>
              <!-- end:: revision details -->
              <!-- start:: project breakdown -->
              <div>
                <app-collapse accordion>
                  <app-collapse-item
                    title="Hard Construction"
                    :amount="ctrBaseQuoteSubtotalSqftA"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="ctrBaseQuoteSubtotalSqftA>ctrBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="ctrBaseQuoteSubtotalSqftA-ctrBaseQuoteSubtotalSqft"
                    :has-changed="ctrBaseQuoteSubtotalSqftA!=ctrBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Furniture"
                    :amount="ffeBaseQuoteSubtotalSqftA"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="ffeBaseQuoteSubtotalSqftA>ffeBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="ffeBaseQuoteSubtotalSqftA-ffeBaseQuoteSubtotalSqft"
                    :has-changed="ffeBaseQuoteSubtotalSqftA!=ffeBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Audio Visual & Networking"
                    :amount="avitBaseQuoteSubtotalSqftA"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="avitBaseQuoteSubtotalSqftA>avitBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="avitBaseQuoteSubtotalSqftA-avitBaseQuoteSubtotalSqft"
                    :has-changed="avitBaseQuoteSubtotalSqftA!=avitBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Soft Costs"
                    :amount="softCostBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  />
                </app-collapse>
              </div>
              <!-- end:: project breakdown -->
            </div>
          </div>
          <div class="col-4">
            <div class="mx-1">
              <div class="w-100 my-1">
                <v-select
                  v-model="secondSpecSet"
                  label="name"
                  :options="specification_sets"
                  item-value="id"
                  :reduce="(specification_sets) => specification_sets.id"
                  :selectable="
                    (specification_sets) =>
                      specification_sets.id != secondSpecSet &&
                      specification_sets.id != firstSpecSet
                  "
                  :clearable="false"
                  @input="setSpecSetsFirstQuote"
                />
              </div>

              <!-- start:: revision details -->
              <div class="my-1 mr-3">
                <div class="row">
                  <div class="col-6">
                    <span class="text-bold text-muted"> PROJECT TOTAL </span>
                    <h5>
                      {{
                        Number(
                          ctrBaseQuoteSubtotalB * 1 +
                            ffeBaseQuoteSubtotalB * 1 +
                            avitBaseQuoteSubtotalB * 1 +
                            softCostBaseQuoteSubtotal * 1
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                  <div class="col-6 text-right">
                    <span class="text-bold text-muted"> PROJECT $/SQFT </span>
                    <h5>
                      {{
                        Number(
                          (ctrBaseQuoteSubtotalB * 1 +
                            ffeBaseQuoteSubtotalB * 1 +
                            avitBaseQuoteSubtotalB * 1 +
                            softCostBaseQuoteSubtotal * 1) /
                            (quote ? quote.sqft : 0)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </h5>
                  </div>
                </div>
              </div>
              <!-- end:: revision details -->
              <!-- start:: project breakdown -->
              <div>
                <app-collapse accordion>
                  <app-collapse-item
                    title="Hard Construction"
                    :amount="ctrBaseQuoteSubtotalSqftB"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="ctrBaseQuoteSubtotalSqftB>ctrBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="ctrBaseQuoteSubtotalSqftB-ctrBaseQuoteSubtotalSqft"
                    :has-changed="ctrBaseQuoteSubtotalSqftB!=ctrBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Furniture"
                    :amount="ffeBaseQuoteSubtotalSqftB"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="ffeBaseQuoteSubtotalSqftB>ffeBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="ffeBaseQuoteSubtotalSqftB-ffeBaseQuoteSubtotalSqft"
                    :has-changed="ffeBaseQuoteSubtotalSqftB!=ffeBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Audio Visual & Networking"
                    :amount="avitBaseQuoteSubtotalSqftB"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="avitBaseQuoteSubtotalSqftB>avitBaseQuoteSubtotalSqft?'increase':'decrease'"
                    :difference="avitBaseQuoteSubtotalSqftB-avitBaseQuoteSubtotalSqft"
                    :has-changed="avitBaseQuoteSubtotalSqftB!=avitBaseQuoteSubtotalSqft"
                  />
                  <app-collapse-item
                    title="Soft Costs"
                    :amount="softCostBaseQuoteSubtotalSqft"
                    :amount-class-prop="''"
                    :budget-a-enabled="true"
                    :diff-class-prop="'text-rigt'"
                    :difference="0"
                  />
                </app-collapse>
              </div>
              <!-- end:: project breakdown -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from '@validations'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCol,
  BFormGroup,
  BFormTextarea,
  BRow,
  BInputGroupAppend,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BFormFile,
  BFormRadio,
    BCard
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItemQuoteCompare.vue'

import vSelect from 'vue-select'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BFormGroup,
    BFormTextarea,
    BRow,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BFormFile,
    vSelect,
    required,
    BFormRadio,
    BCard
  },
  data() {
    return {
      file: null,
      drywall: 0,
      glazing: 0,
      demising: 0,
      demolitionReferenceImage: '',
      plannerNotes: '',
      maxChar: 500,
      tfrNameLabel: '',
      opportunityID: 257,
      sqft: 0,
      userName: localStorage.getItem('name'),
      quote: null,
      // get codes from uniformat-codes-with-categories.json
      excluded_codes_contingency_fee: [
        'Z1070.30.01',
        'Z1070.30.02',
        'Z1070.30.03',
        'Z1070.40',
        'Z1070.50',
        'Z1070.60',
        'Z1070.80',
        'Z1050.20',
        'Z1050.25',
        'Z1050.30',
        'Z1020.10',
      ],
      specification_sets: [],
      firstSpecSet: 0,
      secondSpecSet: 0,
      typeEstimate:'new'
    }
  },
  computed: {
    // * start:: base spec calcs (no spec swaps)
    ctrBaseQuoteSubtotal() {
      if (!this.quote) return 0
      const result = this.quote.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
        0,
      )
      return result
    },
    ctrBaseQuoteSubtotalSqft() {
      if (!this.quote) return 0
      return this.ctrBaseQuoteSubtotal / this.quote.sqft
    },
    ffeBaseQuoteSubtotal() {
      if (!this.quote) return 0
      let sum = 0
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + total,
        0,
      )
      return sum
    },
    ffeBaseQuoteSubtotalSqft() {
      if (!this.quote) return 0
      return this.ffeBaseQuoteSubtotal / this.quote.sqft
    },
    avitBaseQuoteSubtotal() {
      if (!this.quote) return 0
      let sum = 0
      sum = this.quote.avit_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + total,
        0,
      )
      return sum
    },
    avitBaseQuoteSubtotalSqft() {
      if (!this.quote) return 0
      return this.avitBaseQuoteSubtotal / this.quote.sqft
    },
    softCostBaseQuoteSubtotal() {
      if (!this.quote) return 0
      let sum = 0
      console.log('cstrn pm buffer')
      console.log(this.quote.construction_pm_buffer)
      console.log('design ame buffer')
      console.log(this.quote.design_ame_buffer)
      console.log('egineering_fee_price')
      console.log(this.quote.engineering_fee_price)
      console.log('permit fees')
      console.log( this.quote.permit_fees)
      sum = Number(
        (this.quote.construction_pm_buffer * 1
              + this.quote.design_ame_buffer * 1
              + Number(this.quote.engineering_fee_price))
          * this.quote.sqft
          + this.quote.permit_fees,
      )
      return sum
    },
    softCostBaseQuoteSubtotalSqft() {
      if (!this.quote) return 0
      return this.softCostBaseQuoteSubtotal / this.quote.sqft
    },
    // ? end:: base spec cacls
    // * start:: first spec set calculation
    ctrBaseQuoteSubtotalA() {
      let result = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.firstSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.firstSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'CNST',
        )
        filter_items_result.map(c => {
          items_codes_found.push(c.construction_work_id)
        })

        const cstr_items_filtered = this.quote.construction_spec_quote
        // result removed items
        const item_removed = this.quote.construction_spec_quote.filter(e => items_codes_found.indexOf(e.construction_work_id) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.construction_work_id == i.construction_work_id) {
                o.qty = i.qty
              }
            })
          })
        }
        result += cstr_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
          0,
        )
        result += filter_items_result.reduce(
          (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0) * 1)
                + this.contingencyFeeCalcSpecSets(obj)
                + total,
          0,
        )
      } else {
        result = this.quote.construction_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
          0,
        )
      }
      return result
    },
    ctrBaseQuoteSubtotalSqftA() {
      if (!this.quote) return 0
      return this.ctrBaseQuoteSubtotalA / this.quote.sqft
    },
    ffeBaseQuoteSubtotalA() {
      let sum = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.firstSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.firstSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'FF&E',
        )
        // items codes from spec set
        filter_items_result.map(c => {
          items_codes_found.push(c.uid)
        })
        // base spec items from quote
        const ffe_items_filtered = this.quote.ffe_spec_quote.filter(e => items_codes_found.indexOf(e.uid) == -1)
        // result items to make swap
        const item_removed = this.quote.ffe_spec_quote.filter(e => items_codes_found.indexOf(e.uid) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.uid === i.uid) {
                o.qty = i.quantity
              }
            })
          })
        }
        sum += ffe_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
        console.log(sum)
        console.log(filter_items_result.reduce(
          (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)) + total,
          0,
        ))
        sum += filter_items_result.reduce(
          (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)*(1+((Number(obj.unit_delivery_install))/100))) + total,
          0,
        )
      } else {
        sum = this.quote.ffe_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
      }
      return sum
    },
    ffeBaseQuoteSubtotalSqftA() {
      if (!this.quote) return 0
      return this.ffeBaseQuoteSubtotalA / this.quote.sqft
    },
    avitBaseQuoteSubtotalA() {
      let sum = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.firstSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.firstSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'AVIT',
        )
        filter_items_result.map(c => {
          items_codes_found.push(c.uid)
        })

        const avit_items_filtered = this.quote.avit_spec_quote
        // result removed ffe_spec_quote
        const item_removed = this.quote.avit_spec_quote.filter(e => items_codes_found.indexOf(e.uid) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.uid == i.uid) {
                o.qty = i.quantity
              }
            })
          })
        }
        sum += avit_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
        sum += filter_items_result.reduce(
            (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)*(1+((Number(obj.unit_delivery_install))/100))) + total,
            0,
        )
      } else {
        sum = this.quote.avit_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
      }
      return sum
    },
    avitBaseQuoteSubtotalSqftA() {
      if (!this.quote) return 0
      return this.avitBaseQuoteSubtotalA / this.quote.sqft
    },
    // ? end:: firts spec set calculation
    // * start:: second spec set calcs
    ctrBaseQuoteSubtotalB() {
      let result = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.secondSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.secondSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'CNST',
        )
        filter_items_result.map(c => {
          items_codes_found.push(c.construction_work_id)
        })

        const cstr_items_filtered = this.quote.construction_spec_quote.filter(e => items_codes_found.indexOf(e.construction_work_id) == -1)
        // result removed items
        const item_removed = this.quote.construction_spec_quote.filter(e => items_codes_found.indexOf(e.construction_work_id) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.construction_work_id == i.construction_work_id) {
                o.qty = i.qty
              }
            })
          })
        }
        result += cstr_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
          0,
        )
        result += filter_items_result.reduce(
          (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)) + this.contingencyFeeCalcSpecSets(obj) + total,
          0,
        )
      } else {
        result = this.quote.construction_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
          0,
        )
      }
      return result
    },
    ctrBaseQuoteSubtotalSqftB() {
      if (!this.quote) return 0
      return this.ctrBaseQuoteSubtotalB / this.quote.sqft
    },
    ffeBaseQuoteSubtotalB() {
      let sum = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.secondSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.secondSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'FF&E',
        )
        filter_items_result.map(c => {
          items_codes_found.push(c.uid)
        })

        const ffe_items_filtered = this.quote.ffe_spec_quote.filter(e => items_codes_found.indexOf(e.uid) == -1)
        // result removed ffe_spec_quote
        const item_removed = this.quote.ffe_spec_quote.filter(e => items_codes_found.indexOf(e.uid) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.uid == i.uid) {
                o.qty = i.quantity
              }
            })
          })
        }
        sum += ffe_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
        sum += filter_items_result.reduce(
            (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)*(1+((Number(obj.unit_delivery_install))/100))) + total,
            0,
        )
      } else {
        sum = this.quote.ffe_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
      }
      return sum
    },
    ffeBaseQuoteSubtotalSqftB() {
      if (!this.quote) return 0
      return this.ffeBaseQuoteSubtotalB / this.quote.sqft
    },
    avitBaseQuoteSubtotalB() {
      let sum = 0
      const items_codes_found = []
      if (!this.quote) return 0
      if (this.secondSpecSet != 0) {
        const spec_set_selected = this.specification_sets.find(
          s => s.id == this.secondSpecSet,
        )
        const filter_items_result = spec_set_selected.specification_swap_set_items.filter(
          i => i.budget_group == 'AVIT',
        )
        filter_items_result.map(c => {
          items_codes_found.push(c.uid)
        })

        const avit_items_filtered = this.quote.avit_spec_quote.filter(e => items_codes_found.indexOf(e.uid) == -1)
        // result removed ffe_spec_quote
        const item_removed = this.quote.avit_spec_quote.filter(e => items_codes_found.indexOf(e.uid) != -1)
        // set removed items qty in spec sets
        if (item_removed.length > 0) {
          item_removed.map(i => {
            filter_items_result.map(o => {
              if (o.uid == i.uid) {
                o.qty = i.quantity
              }
            })
          })
        }
        sum += avit_items_filtered.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
        sum += filter_items_result.reduce(
            (total, obj) => Number(obj.price_per_unit * 1 * (obj.qty ? obj.qty : 0)*(1+((Number(obj.unit_delivery_install))/100))) + total,
            0,
        )
      } else {
        sum = this.quote.avit_spec_quote.reduce(
          (total, obj) => Number(obj.total_price) + total,
          0,
        )
      }
      return sum
    },
    avitBaseQuoteSubtotalSqftB() {
      if (!this.quote) return 0
      return this.avitBaseQuoteSubtotalB / this.quote.sqft
    },
    // ? end:: sencond spec set calcs
  },
  mounted() {
    this.getSpecSets()
  },
  methods: {
    async sendInitSpecSets(event) {
      event.preventDefault()
      const isValid = await this.$refs.accountRules.validate()
      if (!isValid) return
      if (this.sqft < 1) {
        this.showToast('danger', 'Please enter a valid project sqft', 'Project SQFT')
        return
      }
      try {
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('w2', this.demising)
        formData.append('w1', this.drywall)
        formData.append('g1', this.glazing)
        formData.append('sqft', this.sqft)
        formData.append('opportunityID', this.opportunityID)
        formData.append('userName', this.userName)
        // api/quote-dryrun
        this.$http
          .post('/quote-dryrun', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            this.showToast('success', 'Temporary quote created!', 'Compare spec sets')
            const { data } = response
            this.quote = data
          })
          .catch(error => {
            this.show = false
            this.showToast('danger', error.response.data.message)
            reject()
          })
      } catch (error) {
        reject()
        console.log(error)
      }
    },
    getSpecSets() {
      this.$http
        .get('/specifications/sets')
        .then(response => {
          const { data } = response
          this.specification_sets = data
          if (data.length > 0) this.firstSpecSet = data[0].id
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    contingencyFeeCalc(construction_spec) {
      let result = this.quote.base_spec_version > 3.6
        ? Number(
          Number(construction_spec.total_cost)
                  * (this.quote.contingency_fee_percentage / 100),
        )
        : 0
      if (
        this.excluded_codes_contingency_fee.filter(
          e => e == construction_spec.uniformat,
        ).length > 0
      ) {
        result = 0
      }
      return result
    },

    contingencyFeeCalcSpecSets(construction_spec) {
      let result = this.quote.base_spec_version > 3.6
        ? Number(
          Number(construction_spec.cogs_per_unit * 1 * construction_spec.qty)
                  * (this.quote.contingency_fee_percentage / 100),
        )
        : 0
      if (
        this.excluded_codes_contingency_fee.filter(
          e => e == construction_spec.uniformat,
        ).length > 0
      ) {
        result = 0
      }
      return result
    },
    setSpecSetsFirstQuote() {
      console.log('here')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir] .vs__dropdown-toggle {
  background: white;
}
.increase {
  color: #7ec242;
}

.decrease {
  color: #ea5455;
}
.baseSpecVer {
  color: #b9b9c3;
}

th {
  text-align: left !important;
}
[dir] .table thead th {
  border-bottom: 1px solid #d8d6de;
  border-top: none;
}

.comment-label {
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-group-details {
  border-bottom: 1px solid rgba(31, 41, 47, 0.05);
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid #7ec242;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color: #7ec242;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.project-total-label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #5e5873;
}

.project-total-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #7ec242;
}

.op-sc-details {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.confidential-line {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
  text-transform: uppercase;
}

.credit {
  color: #7ec242;
}

.section-description {
  color: #6e6b7b;
  font-size: 12px;
  font-weight: normal;
}

.base-paragraph {
  font-size: 12px;
}

.card .card-header {
  display: block !important;
}

.different {
  color: #636363;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 18px;
}

.muted {
  color: #b9b9c3;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 18px;
}
</style>
<style lang="scss" scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: unset;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem !important;
  border-top: 0px solid #ebe9f1;
}
</style>
